import React from 'react';
import {Dropdown} from 'wix-ui-core/dropdown';
import {Option} from 'wix-ui-core/dropdown-option';
import st from './Sort.st.css';
import s from './Sort.scss';

import autobind from 'autobind-decorator';
import {Accept, ArrowDown} from '../../../icons/dist';
import {sortingOptions} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISortingOption} from '../../../types/galleryTypes';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface SortProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  sortingOptions: ISortingOption[];
}

@withGlobals
@withTranslations()
@autobind
export class Sort extends React.Component<SortProps, {selected: Option['id']}> {
  constructor(props) {
    super(props);
    this.state = {selected: 'default'};
  }

  private getOptions() {
    return this.props.sortingOptions.map(o => ({
      isDisabled: false,
      isSelectable: true,
      id: o.id,
      value: this.getOptionText(o),
      render: value => (
        <div tabIndex={0} className={s.selectorItem}>
          <span>{value}</span>
          {this.state.selected === o.id && <Accept data-hook="selected-option-icon" />}
        </div>
      ),
    }));
  }

  private getOptionText(o) {
    return this.props.t(o.titleKey);
  }

  private onSelect(option: Option) {
    if (!option) {
      return;
    } //fix for when `option` is null. Might be fixed in the future, then this line can be removed: https://github.com/wix/wix-ui/issues/1016
    this.setState({selected: option.id});
    const sortingOption = sortingOptions.find(o => o.id === option.id);
    this.props.globals.sortProducts(sortingOption);
  }

  public render() {
    return (
      <div {...st('root')}>
        <Dropdown
          {...st('dropdown')}
          openTrigger={'click'}
          initialSelectedIds={['default']}
          onSelect={this.onSelect}
          options={this.getOptions()}>
          <button className={s.selector} data-hook="select-button">
            <span className={s.selectorText}>
              {this.getOptionText(sortingOptions.find(o => o.id === this.state.selected))}
            </span>
            <span>
              <ArrowDown />
            </span>
          </button>
        </Dropdown>
      </div>
    );
  }
}
